import Step from '@/components/global/Step/Step.vue'
// import {from} from 'core-js/fn/array';
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { getRetailPrice } from "@/views/stockCenter/adjustmentApply/components/api";
import ProductItem from './components/ProductItem.vue'
import {
  // orderWeek,
  // 购物车查询
  // orderCar,
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  // getWeek,
  // 商品价格列表
  goodsList,
  // 获取商品列表库存数
  getNumber,
  // 购物车提交
  // cartSubmit,
  // 特惠订单提交
  // specialSubmit,
  // 查看预占用额度明细
  quotaDeatail,
  // 特惠专区接口
  specialZone,
  // 渠道结算接口
  // 获取分销商地址 非 dms
  getAddress,
  // 调用五遍接口
  getSupplierS,
  // dms配送方式
  getDeliveryMode,
  // 查询商品行信息
  getCustomer,
  // 订单提交
  orderSubmit,
  orderSubmit2,
  // 下单完成 删除商品
  deletGoods,
  // 分销价格
  fenxiaoPrice,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory, ctsApi, orderSubmit2C, fxZsyhGetDeliveryMethod, isBrightPilotRegion
} from "./api";
import
moment
  from 'moment';
import { getAdressapi, getWeek, orderCar, orderProductDetail } from "@/views/settlement/api";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    addUserSource,
    addUserTips
  },
  data() {
    return {
      message:'',
      showTipFlag: false,
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      prods: [],
      //form
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: '',
      // 联系人
      contacts: '',
      contactList: [], //联系人列表 
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: '',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '',
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',

      // 省市区三级联动
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '入库仓库',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {
        proviceCode: '',
        cityCode: '',
        townCode: '',
        zCode: '',
      },
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },
      // 要求到货周
      arriveOptions: [],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      // 上个页面传过来的参数
      RouterParams: {
        flag: 1,
        counts: '1,2,1',
        ids: '2,3,2',
        mainId: 2
      },
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      orgCode: '',
      matklId: '',
      orgId: '',
      orgtitle: '',
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: '',
      // 有效截止时间
      startTime: '',
      // 后台接口最大截止时间
      maxEndDate: '',
      // 面包屑
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
      {
        path: "/cart",
        name: "cart",
        title: "购物车"
      }, {
        path: "/confirm/channel",
        name: "channle",
        title: "订单结算"
      }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      //备注
      BZvalue: '',
      // 收货地址
      shippingAddress: [],
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],
      // 采购人员id
      userid: '',
      supplierCode: '',
      supplierId:undefined,
      supplierSumb:'',
      dealerName:undefined,
      supplierName: undefined,
      provinceCode: '',
      cityCode: '',
      districtCode: '',
      URLTO: '',
      saveMoneyValue: {},
      goodListAll:[],
      receiveShop:'',// 销量所述门店
      saleShopList:[],//销量所述门店列表
      townCode: '',//县乡
      defaultTown:'',
      companyList:[],
      fetching:false,
      addressHx:[],
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      shopSelectId:'',
      isShowLogisticsIcon:false,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',// 配送方式回显 index
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      isBright:false,// 判断是试点区域
      defaultVal: [],
      orgList:[]
    };
  },
  watch:{
    GoodsList: {
      handler:function(val,old) {
        if(this.RouterParams.pageUrl !== 'detailPro') {
          // 查询商品分销价格 传入商品数据
          this.getPriceFX(val)
          this.goodsVolume = 0
          this.goodsNum = 0
          val.forEach(item=>{
            this.goodsVolume += (item.loadVolume*item.quantity)
            this.goodsNum+= item.quantity
            this.goodsPrice += ( item.standardPrice * item.quantity)
          })
        }
        if(this.GoodsList.length > 0) {
          this.GoodsList.forEach(el => {
            ctsApi({
              serviceCode: "displayReturnPolicy",
              bigContractNo: "",
              companyCode: el.orgCode,
              contractNo: "",
              distributorsCisCode: this.$store.state.user.userInfo.account.account,
              dlMdmCode: el.agentCode,
              model: el.modelSap || el.b2bName,
              pricingGroup: "",
              tableRow: "",
              isActivityArea: false
            }).then(res=>{
              if(res && res.data && res.data.data && res.data.data.model){
                this.$set(el, "isShowRebate", true)
                this.$set(el, "returnAmount", res.data.data.returnAmount)
                this.$set(el, "upperLimit", res.data.data.upperLimit)
              }
            })
            checkRetailL({id:el.productId}).then(res => {
              if(res.data && res.data.code == 0){
                const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
                el.min = min;
                el.bei = bei;
                el.type = type;
                el.max = max;
                el.salePrice = this.$util.getFloat(Number(money),2)
                el.retailPrice = this.$util.getFloat(Number(money),2)
                el.haveRetailL = haveRetailL;
              }
            })
            // let retailData = {
            //   cisCode:this.$store.state.user.userInfo.customer.cisCode,
            //   productId: el.productId,
            //   refreshPrice: true,
            //   type: 3,
            // }
            // getRetailPrice(retailData).then(res=>{
            //   el.salePrice = this.$util.getFloat(res.data.price,2)
            //   el.retailPrice = this.$util.getFloat(res.data.price,2)
            //   this.$forceUpdate()
            // })
          })

        }


      },
      // 深度观察监听
      deep: true
    },
  },
  mounted() {
    var obj = this.$route.query;
    // var index = obj.orgAndGroup.indexOf("-")
    this.RouterParams = this.$route.query;
    if(this.RouterParams.type =='orderList') {
      this.orgList = this.RouterParams.agentDealers?JSON.parse(this.RouterParams.agentDealers):[]
      this.orgList.forEach(el=>{
        el.supplierName = el.orgName+'-'+el.dealerName
      })
      if( this.RouterParams.agentDealers&&this.orgList.length==1 ) {
        this.supplierId = this.orgList[0].orgId
        this.supplierSumb = this.orgList[0].code
        console.log('this.supplierSumb',this.orgList[0])
        this.supplierName =  this.orgList[0].orgName+'-'+this.orgList[0].dealerName
      }else {
        this.supplierId = undefined
      }

    }
    if( this.RouterParams.pageUrl == 'detailPro' ) {
      // if(this.RouterParams.type !=='orderList' || (this.RouterParams.type =='orderList'&&this.RouterParams.agentDealers.length==1)) {
      //   this.carList()
      // }
      if( this.RouterParams.agentDealers&&this.orgList.length==1 ) {
        this.carList(this.supplierId)
      }else {
        this.carList()
      }

    } else{
      if(this.RouterParams.orgAndGroup) {
        this.RouterParams.orgAndGroup = new Set(this.RouterParams.orgAndGroup.split(','))
        // 循环查商品行信息
        this.RouterParams.orgAndGroup.forEach(item=>{
          // 查询商品行信息
          this.getMessage(item);
        })
      }
    }
    if(this.RouterParams.pageUrl !== 'detailPro') {
      let supplier = ''
      let orgArr = []
      obj.supplierName.forEach(it=>{
        it = it.split('-')
        supplier = it[0]
      })
      this.suppilers = supplier;
    } else {
      this.suppilers = obj.supplierName
    }

    // 收货地址
    this.findAddress();
    // 查询入库仓库getSupplierS
    this.inWarehouse()
    // 结算单位
    this.cglrjsdw()
    // dms 收货地址
    // this.cglrshdz()
    // 业务员
    this.ywy()
    // 采购录入业务员
    this.cglrywy();
    // 配送方式
    // this.getPS()
    // dms订单提交
    this.getShopList()
    this.getuserSorceList()
    this.getuserTipList()
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark
    if(this.RouterParams.skuOrderId || this.RouterParams.saleOrderId){
      const goProduct = JSON.parse(localStorage.getItem("goProduct"));
      const {
        storeId,
        storeName,
        userName,
        phone,
        gender,
        SorceIds,
        userLabel,
        userDetailAddress,
        proviceCode,
        cityCode,
        townCode,
        zCode,
      } = goProduct;
      this.receiveShop = storeName;
      this.salesShopInfoId = storeId;
      this.contacts = userName;
      this.phoneCon = phone;
      this.sexId = gender;
      this.SorceIds = SorceIds;
      this.proObj = {
        ...this.proObj,
        proviceCode,
        cityCode,
        townCode,
        zCode,
      }
      this.defaultVal = [proviceCode, cityCode, townCode, zCode];
      this.userDetailAddress = userDetailAddress;
      if(this.userTipsList && this.userTipsList.length > 0){
        this.userTipsList.forEach(item => {
          if(userLabel.findIndex(el => el == item.id) > -1){
            item.checked = true;
          }
        })
      }
    }
  },
  methods: {
    handleChangeSupplierId(){
      console.log('supplierId',this.supplierName)
      let obj = this.orgList.find((i)=>{
        return i.supplierName ==  this.supplierName;//筛选出匹配数据
      });
      this.supplierSumb = obj.code
      this.carList(obj.orgId)
      this.getFWF(obj.orgId)

    },
    changePhoneNum() {
      if(this.RouterParams.skuOrderId || this.RouterParams.saleOrderId){
        return;
      }
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='04'||this.trans=='08')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }

        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1)
            if(this.userTipsList.length>0){
              this.TipsNames = []
              this.TipsIds = []
              this.userTipsList.forEach(item=>{
                item.checked = false

              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);

                  }
                })
              })
            }else {
              this.sexId = 1
              this.addressHx = []
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
            }
          } else {
            this.sexId = 1
            this.addressHx = []
            this.showSorce = false
            this.TipsIds = []
            this.TipsNames = []
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },
    // 获取配送方式以及判断直送用户是否可选
    getFxGetDeliveryMethod(orgId) {
      const items = [], orgMatkl = []
      if(this.GoodsList && this.GoodsList.length>0) {
        this.GoodsList.forEach(el => {
          if(orgMatkl.length == 0 || !orgMatkl.includes(orgId?`${orgId}-${el.materialGroup}`:`${el.orgId}-${el.materialGroup}`)) {
            orgMatkl.push(orgId?`${orgId}-${el.materialGroup}`:`${el.orgId}-${el.materialGroup}`)
          }
        })
        orgMatkl.forEach((it, index) => {
          items.push({
            orgId: it.split('-')[0],
            matklCode: it.split('-')[1],
            productIds: []
          })
          this.GoodsList.forEach(el => {
            if(it == `${el.orgId}-${el.materialGroup}`) {
              items[index].productIds.push({
                id: el.productId
              })
            }
          })
        })
      }

      let data = {
        orderType:2,
        // serviceCode: 'cisFxZsyhGetDeliveryMethod',
        code:this.$store.state.user.userInfo.customer.cisCode,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode: this.GoodsList[0].agentCisCode,
        items: items,
      }
      fxZsyhGetDeliveryMethod(data).then(res=>{
        if(res.data.code == 0) {
          this.options = res.data.data.items
          console.log('this.options',this.options)
          this.$forceUpdate()
          this.isShowLogisticsIcon = this.options.some(el => ['04', '08'].includes(el.code))
          // 获取接口中check == true 默认选中的下标
          if(!this.RouterParams.skuOrderId && !this.RouterParams.saleOrderId){
            this.transDefault = this.options.findIndex(el => el.check == true)>-1?this.options.findIndex(el => el.check == true):''
            // 赋值配送方式
            this.trans = this.options[this.transDefault].code
            // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
            this.haveDeliveryFee = this.options[this.transDefault].haveDeliveryFee == 1? true : false
            this.$forceUpdate()
          }

        }
      })

    },

    // 查询服务费
    getFWF(orgId){
      if( !this.proObj.zCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      this.startShowTime =''
      this.startTime =''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.productId,		//产品id
          "warehouseInfoId": "",			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": item.quantity,
          zzprdmodel:item.productName,
          orgId: orgId?orgId:item.orgId
        })
      })
      let data = {
        "townCode":  this.proObj.zCode,
        "countyCode": this.proObj.townCode,
        "cityCode":  this.proObj.cityCode,
        "provinceCode":  this.proObj.proviceCode ,
        "warehouseInfoId": '',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopId":  '', //this.shopSelectId,			//门店id
        "documentSource":"5",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      findMoneyBuyBegin(data).then(res => {
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.startTime =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.startTime =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              if( itemFirst.productId == item.productId ){
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
        }
        if(res.data.code == 1 ){
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney','0.00')
            })
          })
        }
      }).catch(error=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.startTime =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)

      })
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    changePrice(item){
      this.$forceUpdate()
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item) {
      this.TipsIds = [];
      this.TipsIds = item;
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {

      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },

    // // 销量所属门店
    getShopList(){
      // cisFxZsyhGetxlShop
      let data = {
        serviceCode: 'cisFxZsyhGetXlShop',
        code:this.$store.state.user.userInfo.customer.cisCode
      }
      ctsApi(data).then(res=>{
        if(res.data.code == 0) {
          res.data.data.forEach(el => {
            el.code = el.cisCode
            el.name = el.fullName
            el.selected = false
          })
          this.saleShopList = res.data.data
          if(this.saleShopList.length>0){
            this.receiveShop = this.saleShopList[0].name;
            this.salesShopInfoId = this.saleShopList[0].code;
            let obj = this.saleShopList.find((i)=>{
              return i.code ===  this.saleShopList[0].code;//筛选出匹配数据
            });
            this.shopSelectId = obj.id
          }
        }
      })
    },
    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value;
      this.salesShopInfoId = e.key;
      this.startShowTime =''
      this.startTime =''
      let obj = this.saleShopList.find((i)=>{
        return i.code ===  e.key;//筛选出匹配数据
      });
      this.shopSelectId = obj.id
      if(this.proObj.zCode && (this.trans=='04' || this.trans=='08')){
        this.getFWF()
      }


    },
    // 地址修改
    onAddressChange(addressValArr) {
      // 老地址
      let oldAdress = this.proObj.zCode
      this.proObj.proviceCode = addressValArr[0];
      this.proObj.cityCode  = addressValArr[1];
      this.proObj.townCode = addressValArr[2];
      this.proObj.zCode  = addressValArr[3];
      this.startTime = ''
      this.startShowTime = ''
      if(oldAdress !== this.proObj.zCode ){
        this.userDetailAddress = ''
      }
      if(this.proObj.zCode && (this.trans=='04' || this.trans=='08')){
        this.getFWF()
      }

    },

    blurAddress(){
      if(! this.proObj.zCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(! this.proObj.zCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }

        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    contactChange(item) {
        this.contacts = item.contact;
        this.phoneCon = item.phone;
        this.changePhoneNum()
    },
    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //是否返利点击
    tradeOnChange(e) {
      // e.target.checked = !e.target.checked;
      this.isTradeChecked = e.target.checked;
    },
    // 收货地址 非dms
    findAddress(matklId, orgId) {
      let data = {
        orgId: this.orgId,
        matklId: this.matklId
      }
      getAddress(data).then(res => {
        this.shippingAddress = res.data.list;
        if (res.data.list.length > 0) {
          let obj = this.shippingAddress.find((i)=>{//listData就是上面的数据源
            return i.id === res.data.list[0].id;//筛选出匹配数据
          });
          // 如果选择的地址状态为D 说明地址失效 弹出提示
          if(obj.regionStatus == 'D') {
            this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
            this.receivehis = ''
            this.distributionAdd.DISCODE = ''
            this.officeCode = ''
            return
          }
          this.officeCode = res.data.list[0].id;
          this.receivehis = res.data.list[0].address;
          this.distributionAdd.DISCODE = res.data.list[0].id;
        }

        this.showAnotheer()
      })
    },
    // 入库仓库
    inWarehouse() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrrkck"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
        var arr = [];
        // if (arr.length != 0) {
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              key,
              value: item[key],
            })
          }
        }
        this.receive = obj.data[0][Object.keys(obj.data[0])[0]];
        this.receiveID = Object.keys(obj.data[0])[0];
        this.orderDis = arr;
        // }

      })
    },
    // 结算单位
    cglrjsdw() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrjsdw"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)

        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              key,
              value: item[key]
            })
          }
        }
        this.invoice = arr[0].value;
        this.invoiceId = arr[0].key;
        this.billToDTOs = arr;
      })
    },

    // 业务员
    ywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "ywy"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
      })
    },
    // 采购录入业务员
    cglrywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        // userAccount: this.$store.state.user.userInfo.customer.customerCode,
        type: "cglrywy"
      }
      getSupplierS(data).then(res => {
        var obj = JSON.parse(res.data)
        this.userid = Object.keys(obj.data[0])[0];
      })
    },
    // 查询商品行信息
    getMessage(item) {
        let params = {
          orgAndGroup: item,
          type: this.RouterParams.purchaseType,
          ids: this.RouterParams.carts
        }
        getCustomer(params).then(res => {
          var objs = res.data.cartDTOs.length>0?res.data.cartDTOs:[]
          if(res.data.cartDTOs.length>0) {
            this.goodListAll.push(...res.data.cartDTOs)
          }

          var total = 0,
            totaleMoney = 0,
            objitem = {},
            arr = [],
            totalVol = 0,
            id = [],
            orgId = [],
            orgCode = [],
            matklCode = [],
            productIdfx = [],
            ordCodefx = [],
            goodsarray = res.data.cartDTOs,
            orgidSubmit = [];
          // 供应商编码
          this.supplierCode = objs[0].agentCode;
          // 商品行信息
          for (let j = 0; j < objs.length; j++) {
            total = objs[j].quantity + total;
            totalVol = objs[j].loadVolume * objs[j].quantity + totalVol;
            orgidSubmit.push(objs[j].orgId);
            this.orgId = orgidSubmit[0];
            objitem = {
              colour: objs[j].color == null ? '' : objs[j].color,
              amount: objs[j].quantity * objs[j].price,
              // 产品编码
              productCode: objs[j].productId,
              // invStatusId:obj[i].
              price: this.goodsPrice,
              model: objs[j].productName == null ? '' : objs[j].productName,
              invStatusType: objs[j].makeUpTypeCode == null ? '' : objs[j].makeUpTypeCode,
              orderedQty: objs[j].quantity == null ? '' : objs[j].quantity,
              priceId: objs[j].priceId == null ? '' : objs[j].priceId,
              productName: objs[j].fullName == null ? '' : objs[j].fullName,
              invStatusId: '',
              cartId: objs[j].id,
              loadVolume: objs[j].loadVolume,
              matklCode: objs[j].materialGroup,
              orgId: objs[j].orgId,
              agentCode:objs[0].agentCode,
              retailPrice:objs[0].retailPrice,
            }
            arr.push(objitem)
            if (objs[j].isFenXiao == "Y" && objs[j].isSell == "Y") {
              productIdfx.push(objs[j].productId)
              ordCodefx.push(objs[j].orgId);
              id.push(objs[j].productId);
              orgId.push(objs[j].orgId);
              orgCode.push(objs[j].orgCode);
              matklCode.push(objs[j].materialGroup);
            } else {
              id.push(objs[j].productId);
              orgId.push(objs[j].orgId);
              orgCode.push(objs[j].orgCode);
              matklCode.push(objs[j].materialGroup);
            }
          }

          this.purchaseOrderItem = arr;
          // 查询商品价格信息
          id = id.join(',');
          orgId = orgId.join(',');
          orgCode = orgCode.join(',')
          let data = {
            code: id,
            orgId: orgId,
            orgCode: orgCode
          }
          goodsList(data).then(res => {
            objs.forEach(item=>{
              for (let i = 0; i < res.data.length; i++) {
                if (item.productId == res.data[i].productCode) {
                  totaleMoney = res.data[i].price * objs[i].quantity + totaleMoney;
                  goodsarray[i].price = res.data[i].price
                  // this.GoodsList[i].price = res.data[i].price;
                  // this.GoodsList[i].amount = res.data[i].price * objs[i].quantity;
                }
              }
            })
            this.GoodsList = this.GoodsList.concat (goodsarray);
            this.getFxGetDeliveryMethod()
            this.orgId = this.GoodsList.length>0?this.GoodsList[0].orgId:'';
            this.matklId= this.GoodsList.length>0?this.GoodsList[0].materialGroupId:'';
            // 收货地址
            this.findAddress();
          })

          // 分销价格
          ordCodefx = ordCodefx.join(',');
          productIdfx = productIdfx.join(',')
          let datas = {
            productId: productIdfx,
            orgId: ordCodefx
          }
          fenxiaoPrice(datas).then(res => {
            for (let i = 0; i < res.data.list.length; i++) {
              if (objs[i].productId == res.data.list[i].productId) {
                totaleMoney = res.data.list[i].standardPrice * objs[i].quantity + totaleMoney;
                // goodsarray[i].price = res.data.list[i].standardPrice;
                // goodsarray[i].lineprice = res.data.list[i].retailPrice;
                // this.GoodsList[i].price = res.data.list[i].standardPrice;
                // this.GoodsList[i].amount = res.data.list[i].standardPrice * objs[i].quantity;
              }
              this.goodsPrice += ( res.data.list[i].standardPrice*objs[i].quantity)
            }
          })
        })
    },

    // 商品详情跳转过来的调用这个方法
   async carList(orgId) {
     this.goodsPrice = 0
     var params = this.RouterParams;
      orderProductDetail(params).then(res => {
        if(res.data.msg) {
          this.$message.warning(res.data.msg)
          return
        }
        this.goodsNum = 0
        this.goodsVolume = 0
        for (let key in res.data.cartOrder.cartMap) {
          var aList = res.data.cartOrder.cartMap[key]
          this.GoodsList = aList;
        }
        this.supplierCode = aList[0].agentCode;
        this.orgId = this.GoodsList.length>0?this.GoodsList[0].orgId:'';
        this.matklId= this.GoodsList.length>0?this.GoodsList[0].materialGroupId:'';
        // 收货地址
        this.findAddress()
        if( this.GoodsList.length > 0) {
          this.GoodsList.forEach(item=>{
            this.goodsVolume += (item.loadVolume*item.quantity)
            this.goodsNum+= item.quantity
            let datas = {
              productId: item.productId,
              orgId: orgId?orgId:item.orgId
            }
            fenxiaoPrice(datas).then(res => {
              item.price = res.data.list[0].standardPrice;
              item.lineprice = res.data.list[0].retailPrice;
              item.price = res.data.list[0].standardPrice;
              item.amount = res.data.list[0].standardPrice * item.quantity;
              this.goodsPrice += item.amount
            })
          })
        }
        this.getFxGetDeliveryMethod(orgId)

      }).catch(err => {
        console.log(err)
      })

    },

    // 查询商品分销价格
    getPriceFX(val) {
      let productIds = []
      let orgIds = []
      val.forEach((item,index)=>{
        productIds.push(item.productId)
        orgIds.push(item.orgId)
      })
      let datas = {
        productId: productIds,
        orgId: orgIds
      }
      fenxiaoPrice(datas).then(res => {
        for (let i = 0; i < res.data.list.length; i++) {
          if (val[i].productId == res.data.list[i].productId) {
            val[i].price = res.data.list[i].standardPrice;
            val[i].lineprice = res.data.list[i].retailPrice;
            this.GoodsList[i].price = res.data.list[i].standardPrice;
            this.GoodsList[i].amount = res.data.list[i].standardPrice * val[i].quantity;
          }
        }
      })
    },
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;
      })
    },
    // 省 选择
    handleProvinceChange(value, e) {
      this.proObj.proviceCode = this.provinceCode;
      this.choseCity(this.provinceCode)
    },
    // 获取城市
    choseCity(code) {
      let data = {
        proviceCode: code
      }
      cityChange(data).then(res => {
        this.getCity = res.data;
        //获取城镇区
        this.getTowns(this.cityCode)
        this.proObj.cityCode = this.cityCode
      }).catch(err => {
        console.log(err);
      })
    },
    // 获取城镇区
    getTowns(code) {
      let data = {
        cityCode: code
      }
      townChange(data).then(res => {
        this.getTown = res.data;
        this.proObj.townCode = this.districtCode
        this.getqu(this.districtCode);
      }).catch(err => {
        console.log(err);
      })
    },
    // 获取乡镇
    getqu(code) {
      let data = {
        districtCode: code
      }
      getQu(data).then(res => {
        this.qu = res.data;
        this.proObj.zCode = res.data[0].townCode;
      })
        .catch(err => {
          console.log(err);
        })
    },
    // 城市选择
    handleCityChange(e) {
      this.proObj.cityCode = this.cityCode;
    },
    // 城镇选择
    handleTownChange(e) {
      this.proObj.townCode = this.districtCode;
      this.getqu(this.districtCode);
    },
    //乡镇
    handleZhenChange(e) {
      this.proObj.zCode = e;
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    moreBill() {

    },
    onGiveType(e) {
      this.trans = this.options[e.target.value].code;
      if((this.RouterParams.skuOrderId || this.RouterParams.saleOrderId) && (this.trans == '01' || this.trans == '02')){
        this.$message.warning('审核出库O2O单时仅可选择toC配送，由代理商直发用户！')
        return
      }
      // 是否展示服务费 根据配送方式中返回字段判断 // 0否，1是
      this.haveDeliveryFee = this.options[e.target.value].haveDeliveryFee == 1? true : false
      this.startTime = ''
      this.startShowTime = ''
      if(this.proObj.zCode && (this.trans=='04' || this.trans=='08')){
        this.getFWF()
      }

      if( this.trans !='04' && this.trans !='08' && !this.RouterParams.skuOrderId && !this.RouterParams.saleOrderId) {
        this.proObj.proviceCode =''
        this.proObj.cityCode  =''
        this.proObj.townCode =''
        this.proObj.zCode  =''
      }
      if( this.trans =='04' || this.trans =='08') {
        this.changePhoneNum()
      }

      if (this.houseAddress != '统仓地址') {
        if (e.target.value == 3) {
          this.distributors = false;
          this.userAdderss = false;
        } else if (this.trans == 502005) {
          if (!this.DisList || this.DisList.length == 0) {
            this.$warning({
              content: '暂无可选分销商，请选择其他配送方式',
            });
            // this.$message.info("暂无可选分销商，请选择其他配送方式");
            return;
          }
          this.distributors = true;
          this.userAdderss = false
          // 分销商 分销地址
          this.showAnotheer()
        } else {
          this.distributors = false;
          this.userAdderss = false;
        }
      }
    },
    // 分销商 分销地址切换获取手机号
    showAnotheer() {
      if (this.RouterParams.type === 'orderList') {
        return
      }
      getPhone({
        id: this.distributionAdd.DISCODE
      }).then(res => {
        // this.contacts = res.data.contact;
        // this.phoneCon = res.data.phone;
        if(res.data.list.length > 0) {
            this.contacts = res.data.list[0].contact;
            this.phoneCon = res.data.list[0].phone;
            this.contactList = res.data.list
           this.changePhoneNum()
        } else {
            this.contacts = '';
            this.phoneCon = '';
        }
      })
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
      this.accountShow = !this.accountShow;
    },
    // 开票户头相关函数
    handleChange(value, e) {
      this.invoice = value;
      this.invoiceId = e.data.key;
      this.accountShow = !this.accountShow;
    },
    handleBlur() { },
    handleFocus() { },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 直送用户/送装一体地区校验
    async deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
              orgId: el.orgId,
              matklId: el.materialGroupId
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.proObj.provinceCode,
        "cityCode":this.proObj.cityCode,
        "districtCode":this.proObj.townCode,
        "townCode":this.proObj.zCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:5,
        deliveryMethod:this.trans,
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      return ctsApi(data)
    },
    // 是否是璀璨试点区域
    isBrightMethods(){
      return isBrightPilotRegion({regionTownCode:this.proObj.zCode})
    },
    // dms 订单提交
   async submitOrder() {
      if(this.RouterParams.type === 'orderList' && this.orgList.length>1 && !this.supplierName){
        this.$message.warning('请选择供应商')
        return
      }
      if((this.RouterParams.skuOrderId || this.RouterParams.saleOrderId) && (this.trans == '01' || this.trans == '02')){
        this.$message.warning('审核出库O2O单时仅可选择toC配送，由代理商直发用户！')
        return
      }
      if(!this.trans)  {
        this.$message.warning('请选择配送方式！')
        return
      }
      if(!this.trans== '04') {
        if( !this.receive){
          this.$message.warning('请选择入库仓库！')
          return
        }
        if(!this.receivehis)  {
          this.$message.warning('请选择收货地址！')
          return
        }
      }
      if(['04', '08'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['04'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          this.isLoading = false
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['08'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          this.isLoading = false
          return false
        }
        if(!this.proObj.zCode) {
          this.$message.warning('请选择收货地区')
          this.isLoading = false
          return false
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId) {
          this.$message.warning('请选择销量所属门店')
          this.isLoading = false
          return false
        }
        if(  !this.sexId) {
          this.$message.warning('请选择用户性别')
          this.isLoading = false
          return false
        }
        if(this.TipsIds.length == 0) {
          this.$message.warning('请选择用户标签')
          this.isLoading = false
          return false
        }
        if(  !this.SorceIds) {
          this.$message.warning('请选择用户来源')
          this.isLoading = false
          return false
        }
        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          return false
        }

      }
      // if(this.trans== '04') {
      //   if(!this.salesShopInfoId) {
      //     this.$message.warning('请选择销量所属门店')
      //     return false
      //   }
      //   if(!this.proObj.zCode){
      //     this.$message.warning('请选择收货地区')
      //     return false
      //   }
      //   if(!this.userDetailAddress) {
      //     this.$message.warning('请填写详细地址')
      //     return false
      //   }
      //   if(this.TipsIds.length == 0) {
      //     this.$message.warning('请选择用户标签')
      //     return false
      //   }
      //   // if(this.isAddressSupport == 0) {
      //   //   this.$message.warning('直送用户暂不支持跨营销中心，请修改配送方式或修改收货地区')
      //   //   return false
      //   // }
      //   // if(this.isAddressSupport == 2) {
      //   //   this.$message.warning('该地区暂不支持直送用户，请修改配送方式或修改收货地区')
      //   //   return false
      //   // }
      //
      // }
      if(!this.contacts) {
        this.$message.warning('请填写联系人！')
        this.isLoading = false
        return
      }
      if(!this.phoneCon) {
        this.$message.warning('请填写联系电话！')
        this.isLoading = false
        return
      }

      if (this.GoodsList.length == 0) {
        this.$message.warning('商品数量不能为空')
        this.isLoading = false
        return
      }
      if( this.trans== '04' || this.trans== '08') {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for (let i = 0; i < arr.length; i++) {
          if (!arr[i].salePrice) {
            this.$message.warning(`请输入第${i + 1}件商品的零售价格`)
            this.isLoading = false
            return false
          }
          // if ( Number(arr[i].retailPrice) && (( Number(arr[i].salePrice) <  Number(arr[i].retailPrice * 0.5) ) || (  Number(arr[i].salePrice) >  Number(arr[i].retailPrice * 1) ))){
          //   this.$message.warning(`建议零售价为${arr[i].retailPrice}元，可填范围在0.5倍到1倍之间。`)
          //   this.isLoading = false
          //   return false
          // }
          if (arr[i].type == 1 && arr[i].salePrice) {
            if (Number(arr[i].salePrice) < arr[i].min || Number(arr[i].salePrice) > arr[i].max) {
              if (arr[i].haveRetailL) {
                this.$message.warning(`请在默认建议零售价的${arr[i].bei}倍范围内录入价格`);
              } else {
                this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
              }
              return;
            }
          }
        }
        // 获取产品是璀璨的长度
        let length = this.GoodsList.filter(it => it.productLabel && it.productLabel.indexOf('15691143849') > -1).length
        // 产品有璀璨 并且 有省市区县 并且是直送用户或者送装一体
        if (this.proObj.zCode && length > 0) {
          const response = await this.isBrightMethods()
          this.isBright = response.data.code == 0 ? response.data.data : false
        } else {
            this.isBright = false
          }
        }

      // 当前日期    
      var now = new Date();
      var year = now.getFullYear(); //得到年份
      var month = now.getMonth() + 1; //得到月份
      var date = now.getDate(); //得到日期
      // var day = now.getDay(); //得到周几
      var hour = now.getHours(); //得到小时
      var minu = now.getMinutes(); //得到分钟
      var sec = now.getSeconds(); //得到秒
      var time = ''
      time = year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
      this.isLoading = true;
      let saleItem = []
      this.GoodsList.forEach(item=>{
        saleItem.push({
          productCode:item.productId,
          model:item.productName,
          orderedQty:item.quantity,
          invStatusId:'',
          invStatusType:'',
          price:item.price,
          amount:item.price*item.quantity,
          orgCode:item.orgId,
          salePrice:Number(item.salePrice),
          cartId:item.id
        })
      })
      let purchaseOrder = {
        //  详细地址
        // address: this.userDetailAddress,
        // address: this.receivehis,
        contactInfo: this.phoneCon,
        // billtoId: this.invoiceId,
        // provinceId: this.proObj.proviceCode,
        // cityId: this.userAdderss ? this.proObj.cityCode : '',
        // townId: this.userAdderss ? this.proObj.townCode : '',
        // countryId: this.userAdderss ? this.proObj.zCode : '',
        message: this.BZvalue,
        // addressId: this.distributors ? this.distributionAdd.DISCODE : '',
        // addressId: this.officeCode,
        // totalAmount: this.totalAmount,
        // totalOrderedQty: this.totalOrderedQty,
        warehouseId: this.receiveID,
        deliveryMode: this.trans,
        // orgCode: this.orgId,
        contact: this.contacts,
        // 商品信息
        purchaseOrderItem: saleItem,
        // 当前日期
        // documentDate: time,
        supplierCode:this.RouterParams.type !== 'orderList' ? this.supplierCode : this.supplierSumb,
        userId: this.userid,
        // cisAddressId: this.officeCode,
        edt: this.startTime&&this.haveDeliveryFee?this.startTime.replace(/\//g,"-"):'',
        dataSource: 'xtw_pc',
        cisAddressId: this.trans != '04'&this.trans != '08' ?this.officeCode : '', // 收货地址id
        address: this.trans != '04'&this.trans != '08' ? this.receivehis : this.userDetailAddress, // 收货地址
        // orgCode,
        storeCode:  this.salesShopInfoId, // 销量所属门店
        sourceId:  this.SorceIds, // 用户来源
        customerLabels: this.TipsIds.join(','), // 用户标签
        provinceId: this.proObj.proviceCode, // 省id
        cityId: this.proObj.cityCode, // 市id
        countryId: this.proObj.townCode, // 区id
        townId: this.proObj.zCode, // 街道id
        gender: this.trans != '04'&&this.trans != '08' ? '' : this.sexId, // 性别
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
        brightFlag:this.isBright,
        skuOrderId: this.RouterParams.skuOrderId, // o2o订单id
        saleOrderId: this.RouterParams.saleOrderId,
      }
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        purchaseOrder: purchaseOrder,
      }
      if(this.trans == '04' || this.trans == '08'){
        orderSubmit2C(data).then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == '1') {
            this.isLoading = false;
            this.$message.warning(obj.msg);
          } else if (obj.code == '0') {
            if(obj.deliveryOrderNum) {
              this.$router.push({
                path: "/serviceFeeModule/pay",
                query: {
                  id:obj.deliveryOrderNum,
                  batch:0,
                  b2bOrderCode: obj.msg,
                  orgName: '订单号',
                  channel: 1
                }
              });
              return
            }
            this.$router.push({
              path: '/confirm/confirmsuccess',
              query: {
                b2bOrderCode: obj.msg,
                orgName: '订单号',
                channel: 1
              }
            })
            // this.deletGoods();
          }
        })
      } else {
        orderSubmit2(data).then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == '1') {
            this.isLoading = false;
            this.$message.warning(obj.msg);
          } else if (obj.code == '0') {
            if(obj.deliveryOrderNum) {
              this.$router.push({
                path: "/serviceFeeModule/pay",
                query: {
                  id:obj.deliveryOrderNum,
                  batch:0,
                  b2bOrderCode: obj.msg,
                  orgName: '订单号',
                  channel: 1
                }
              });
              return
            }
            this.$router.push({
              path: '/confirm/confirmsuccess',
              query: {
                b2bOrderCode: obj.msg,
                orgName: '订单号',
                channel: 1
              }
            })
            // this.deletGoods();
          }

        })
      }

    },
    // // 删除接口
    // deletGoods() {
    //   let params = {
    //     cartIds: this.RouterParams.carts
    //   }
    //   deletGoods(params).then(res => {
    //   })
    // },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.startTime && this.haveDeliveryFee){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.startTime){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    handleTimeChange(value,dateString) {
      this.startTime = dateString;
      this.endOpen = false
    },
    // // 控制可选时间范围
    // disabledDate(current) {
    //   // current < moment(new Date(2020 - 11 - 11)) ||
    //   return current < moment().startOf('days') || current > new Date(this.maxEndDate)
    // },
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/cart");
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      if(this.RouterParams.skuOrderId || this.RouterParams.saleOrderId){
        return;
      }
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      // 获取手机号
      getPhone({
        id: e.key
      }).then(res => {
        // this.contacts = res.data.contact;
        // this.phoneCon = res.data.phone;
        if(res.data.list.length > 0) {
            this.contacts = res.data.list[0].contact;
            this.phoneCon = res.data.list[0].phone;
            this.contactList = res.data.list
            this.changePhoneNum()
        } else {
            this.contacts = '';
            this.phoneCon = '';
        }
      })
    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      // 筛选出来当前选择的数据
      let obj = this.shippingAddress.find((i)=>{//listData就是上面的数据源
        return i.id === e.key;//筛选出匹配数据
      });
      // 如果选择的地址状态为D 说明地址失效 弹出提示
      if(obj.regionStatus == 'D') {
        this.$message.warning('由于行政区划调整，请您及时更新您的收货地址信息',5)
        this.receivehis = ''
        this.distributionAdd.DISCODE = ''
        this.officeCode = ''
        return
      }
      this.receivehis = value;
      this.distributionAdd.DISCODE = e.key;

      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.showAnotheer()
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      }).then(res => {
      }).catch(err => {
        console.log(err)
      })
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList()
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {
        this.choseDistribution = res.data
        this.distributionAdd.DISCODE = res.data[0].id
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;

    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      if(this.RouterParams.skuOrderId || this.RouterParams.saleOrderId){
        return;
      }
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value
      this.distrAddress = value;
      getPhone({
        id: e.key
      }).then(res => {
        if(res.data.list.length > 0) {
            this.contacts = res.data.list[0].contact;
            this.phoneCon = res.data.list[0].phone;
            this.contactList = res.data.list
            this.changePhoneNum()
        } else {
            this.contacts = '';
            this.phoneCon = '';
        }
      })
    },
    // 分销商地址切换获取联系人
    getPeople() {
      getPhone()
    },
    addItem() {
      //this.items.push(`New item ${index++}`);
    },
    // 监听输入变化
    handleChangeInt(e) {
    },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType
      }
      quotaDeatail(data).then(res => {
        this.data = res.data.dataList;
        this.pager.count = res.data.dataList.length;
      })
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    }
  }
}