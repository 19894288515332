<template>
  <div class="product-wrap">
    <div class="productTop">
      <div class="item1">
        <div class="item1Img">
          <!-- ../../../assets/shoppingCart/product.png -->
          <!-- <img :src="item.materialGroupId+'/'+item.productId+'/180-180/'+item.picture" alt /> -->
          <h-img
            class="img"
            :imgUrl="item.materialGroup + '/' + item.productId + '/180-180/' + item.picture"
            :errorImg="'/assets/new/images/product/' +item.materialGroup + '.jpg'"
            :isUseBasePath="true"
          ></h-img>
        </div>
        <div class="item1Val">
          <div class="item1Title">
            <p v-if="item.b2bName">
              <span  v-if="item.productLabel && item.productLabel.indexOf('15691143850')!==-1" class="freeShipping">免运费</span>
              <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
              {{item.b2bName}}</p>
          </div>
          <p class="brandBox">
            <span style="margin-right:12px;">品牌：{{item.brandName}}</span>
            <span v-if="item.color">颜色：{{item.color}}</span>
          </p>
          <p v-if="item.loadVolume" style="margin-top: 8px;">体积：{{$util.getFloat(item.loadVolume,3)}}m³</p>
        </div>
      </div>
      <div class="item2">
        <div class="item2Title">
          ￥{{$util.getFloat(item.price,2)}}
          <span
            style="margin-left:5px;"
            v-if="item.lineprice"
          >￥{{$util.getFloat(item.lineprice,2)}}</span>
        </div>
        <!-- <div class="item2Item"> -->
          <!-- <p>固定折扣：{{item.fixedDiscount}}%</p> -->
          <!-- <p v-show="item.pricingGroup">定价组：{{item.pricingGroup}}</p> -->
<!--          <p v-show="item.makeUpTypeName">补差类型：{{item.makeUpTypeName}}</p>-->
          <!-- <p>单台返利30元</p> -->
        <!-- </div> -->
        <div class="rebate-div" v-if="item.isShowRebate">最高可享台返{{Number(item.returnAmount || 0).toFixed(2)}}元，政策后提货价{{Number(item.price - (item.returnAmount || 0)).toFixed(2)}}元</div>
      </div>
      <div class="item3">
        <div class="item3Title">×{{item.quantity}}</div>
        <div class="item3Item">
          <div class="item3Items">
            <!-- <p v-if="item.customerCode">海信库存:{{item.customerCode}}</p>
            <p v-if="item.shareOrgFlag">共享库存:{{item.shareOrgFlag}}</p>-->
          </div>
          <!-- <div class="item3Items">
            <p>待总部评审(20)</p>
            <p>待发货(10)</p>
            <p>已商收(24)</p>
            <p>待发货(20)</p>
            <p>甩货(10)</p>
          </div>
          <p>已取消(34)</p>-->
        </div>
      </div>
      <div class="item4" v-if="trans !='04'&&trans !='08'">
        <div class="item4Title">小计：￥{{$util.getFloat(item.price*item.quantity,2)}}</div>
      </div>
      <div class="item4"  v-if="(trans=='04'||trans=='08')&&!haveDeliveryFee">
        <div class="item4Title">小计：￥{{$util.getFloat(item.price*item.quantity,2)}}</div>
        <div class="purchase" style="margin-top:6px;line-height: 16px" >
          <div class="price">零售价格：￥
            <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                  v-if="!idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
            <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
          </div>
          <span v-if="!skuOrderId" @click="edit(item)" class="edit">{{idEdit?'完成':'编辑'}}</span>
        </div>
      </div>
      <div class="item4" v-if="(trans=='04'||trans=='08')&&haveDeliveryFee">
        <div class="item4Title">小计：</div>
        <p style="margin-top:14px;line-height: 16px;font-size: 12px">货款:￥{{$util.getFloat(item.price*item.quantity,2)}}</p>
        <p style="margin-top:8px;line-height: 16px;font-size: 12px">服务费:￥{{item.serviceMoney?Number(item.serviceMoney).toFixed(2):'0.00'}}</p>
        <div class="purchase" style="margin-top:6px;line-height: 16px" >
          <div class="price">零售价格：￥
            <span :style="{color:Number(item.salePrice)>0?'#262626':'#777'} "
                  v-if="!idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}
              </span>
            <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text" v-if="idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 120px" v-model="item.salePrice" />
          </div>
          <span v-if="!skuOrderId" @click="edit(item)" class="edit">{{idEdit?'完成':'编辑'}}</span>
        </div>

        <!-- 采购订单详情取消 -->
        <!-- <p class="purchase" @click="cancleClick(item)">取消</p> -->
      </div>
    </div>
    <!-- 通用结算页面显示内容 -->
    <!-- v-if="settlement" -->
    <!-- item.preferCode -->
    <div v-if="false">
      <div class="line"></div>
      <div class="settlement">
        <div class="settlementChose" @click="handleChange(makeShure)">
          <a-radio :checked="makeShure"></a-radio>
        </div>
        <div>
          <div class="settleTitle">{{item.preferCode}}</div>
          <div class="settlementVal">
            <p>批次：{{item.batch}}</p>
            <p>提货价：￥{{item.standardPrice}}</p>
            <p v-if="item.reduce">直扣：￥{{item.straightBuckle}}</p>
            <p v-if="item.specialPrice">开票价：￥{{item.billPrice}}</p>
            <p>总数量：10</p>
            <p>可购数量：7</p>
            <p>已购数量：3</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRetailPrice } from "@/views/stockCenter/adjustmentApply/components/api";

export default {
  name: "ProductItem",
  props: {
    item: {
      required: true
    },
    settlementItem: {
      required: false
    },
    trans:{
      required: true
    },
    haveDeliveryFee:{
      required: true
    },
    skuOrderId: {
      required: false
    }
    // // 特购专区
    // settlement: {
    //   required: true,
    //   default: false
    // },
  },
  data() {
    return {
      makeShure: true,
      idEdit:false
    };
  },
  watch:{
    trans: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    item: {
      handler: function(val, oldVal) {
        // let retailData = {
        //     cisCode:this.$store.state.user.userInfo.customer.cisCode,
        //     productId: val.productId,
        //     refreshPrice: true,
        //     type: 3,
        //  }
        //   getRetailPrice(retailData).then(res=>{
        //     this.item.salePrice = this.$util.getFloat(res.data.price,2)
        //     this.item.retailPrice = this.$util.getFloat(res.data.price,2)
        //     console.log(this.item.retailPrice)
        //     this.$forceUpdate()
        //   })
      },
      // 深度观察监听
      deep: true
    },
    haveDeliveryFee: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
  },
  methods: {
    edit(item) {
       this.idEdit = !this.idEdit
    },
    changePrice(item,e){
      this.$emit("changePrice", item);
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<style scoped lang="less">
.product-wrap {
  width: 1140px;
  padding: 23px 0 20px 24px;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  background: #F7F7F7;
  border-radius: 8px;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 392px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-top: -2px;
        width: 100px;
        height: 104px;
        background: #fff;
        margin-right: 16px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
         .img {
           width: 80px;
           height: 80px;
         }
      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          p {
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
            font-weight: 500;
            .freeShipping {
              border: none;
              font-size: 12px;
              color: #fff;
              // width: 42px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
              border-radius: 2px;
              padding:0 2px;
              font-weight: 400;
            }
            .bright {
              font-size: 12px;
              color: #433538;
              width: 30px;
              height: 16px;
              text-align: center;
              line-height: 16px;
              background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
              border-radius: 2px;
              margin:0 0px 0 4px;
              font-weight: 400;
            }
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {
          margin-top: 15px;
          font-size: 12px;
          color: #777;
          line-height: 16px;
          margin-left: -6px;

        }

      }
    }
    .item2 {
      width: 210px;
      margin-left: 60px;
      position: relative;
      .item2Title {
        width: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        font-weight: 500;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;
        }
      }
      .item2Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-right: 10px;
          margin-top: 8px;
        }
      }
      .rebate-div{
        height: 16px;
        line-height: 16px;
        color: #FC5260;
        font-size: 12px;
        position: absolute;
        top: 40px;
        left: 0;
        width: 400px;
      }
    }
    .item3 {
      width: 160px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        font-weight: 500;
      }
      .item3Item {
        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 380px;
      padding-top: 5px;
      box-sizing: border-box;
      //margin-left: 24px;
      .item4Title {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        font-weight: 500;
      }
      .purchase {
        //cursor: pointer;
        //margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        display: flex;
        flex-direction: row;
        align-items: center;
        .price{
          display: flex;
          flex-direction:row;
          align-items: center;
          font-size: 12px
        }
        .edit {
          cursor: pointer;
          color: #1890FF;
          margin-left: 16px;
          font-size: 12px
        }
      }
      //.purchase:hover {
      //  color: #00aaa6;
      //}
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .settleTitle {
      font-size: 14px;
      color: #777777;
      line-height: 16px;
      margin-left: 24px;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    .settlementVal {
      width: 800px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 24px;
      p {
        width: 120px;
        text-align: left;
        font-size: 12px;
        color: #777;
        line-height: 16px;
        margin-right: 50px;
        margin-top: 8px;
      }
    }
  }
}
</style>